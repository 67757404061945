import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import axios from 'axios'
import echarts from 'echarts'
import ZkTable from 'vue-table-with-tree-grid'
import {hasPermission} from './utils/permissionDirect'
import Print from 'vue-print-nb'
const Plugins = [hasPermission]
import GM from './utils/common.js'
import dayjs from 'dayjs';
import '/public/css/common.css';

Vue.prototype.$GM = GM
Vue.use(Print);
Plugins.map((plugin) => {
    Vue.use(plugin)
})
Vue.use(ZkTable)
Vue.use(echarts)
Vue.prototype.dayjs = dayjs;
NProgress.configure({ease: 'ease', speed: 500});
NProgress.configure({minimum: 0.3});

let BASE_API_URL="https://pgdp.luysoft.com/market";
// let BASE_API_URL="http://192.168.3.84:9003";
Vue.prototype.$http = axios
Vue.prototype.BASE_API_URL=BASE_API_URL
axios.defaults.baseURL = BASE_API_URL

//请求拦截器
axios.interceptors.request.use(config => {
        NProgress.start()
        config.headers.Authorization = LocalStorage.get(LOCAL_KEY_ACCESS_TOKEN);
        return config;
    }
    , error => {
        return Promise.reject(error)
    });

//响应拦截器
axios.interceptors.response.use(
    function (response) {
        NProgress.done();
        const res = response.data;
        if (res.success) {
            return response;
        }

        if (res.data!=null&&res.data.errorCode === 50001) {
            LocalStorage.clearAll();
            return router.push("/login");
        }
        return response;
    },
    function (error) {
        return Promise.reject(error)
    }
);

// main.js
function formatDate(date, fmt) {
    date = new Date(date);
    if (typeof(fmt) === "undefined") {
        fmt = "yyyy-MM-dd HH:mm:ss";
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'H+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + ''
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length));
        }
    }
    return fmt
};

//定义一个时间过滤器
Vue.filter("FormatDate", function(date, fmt) {
    return formatDate(date, fmt);
});

/**
 * 自定义权限指令
 */
Vue.config.productionTip = false
Vue.use(ElementUI)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')



