import Vue from 'vue'
import VueRouter from 'vue-router'
import VueWechatTitle from 'vue-wechat-title';

Vue.use(VueWechatTitle)
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import( '../views/login.vue'),
        meta:{
            title:'松下软件市场'
        }//登入页面
    },

    {
        path: '/home',
        component: () => import( '../views/home.vue'), //后台主体框架
        redirect: '/system/welcome',
        children: [
            {
                path: '/system/welcome',
                name: 'Welcome',
                component: () => import( '../views/welcome.vue'), //系统欢迎页
                meta:{
                    title:'松下软件市场'
                },
            },
            {
                path: '/system/password',
                name: 'password',
                component: () => import( '../views/system/password.vue'), //修改密码
                meta:{
                    title:'松下软件市场'
                },
            },
            {
                path: '/system/users',
                name: 'Users',
                component: () => import( '../views/system/user.vue'), //用户管理
            },
            {
                path: '/system/roles',
                name: 'Roles',
                component: () => import( '../views/system/role.vue'), //角色管理
                meta:{
                    title:'松下软件市场'
                },
            },
            {
                path: '/system/menus',
                name: 'Menus',
                component: () => import( '../views/system/menu.vue'), //菜单管理
                meta:{
                    title:'松下软件市场'
                },
            },

            {
                path: '/applicationProcessing/applicationProcessing',
                name: 'applicationProcessing',
                component: () => import( '../views/applicationProcessing/applicationProcessing.vue'), //申请管理
                meta:{
                    title:'松下软件市场'
                },
            },

            {
                path: '/factory/factory',
                name: 'factory',
                component: () => import( '../views/factory/factory.vue'), //工厂管理
                meta:{
                    title:'松下软件市场'
                },
            },

            {
                path: '/software/software',
                name: 'software',
                component: () => import( '../views/software/software.vue'), //软件
                meta:{
                    title:'松下软件市场'
                },
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

import store from '../store'//引入store

//白名单页面
const whiteList=[
    "/system/password"
];

//路由导航守卫
router.beforeEach((to, from, next) => {

    const token = LocalStorage.get(LOCAL_KEY_ACCESS_TOKEN);
    if (to.path === '/login') {
        if (!token) {
            return next();
        } else {
            return next({path: '/home'})
        }
    }

    if (to.path === '/error/401') {
        return next();
    }

    let expireTime = LocalStorage.get("expiretime");
    if(expireTime != undefined && expireTime * 1 - new Date().getTime() > 0) {
        expireTime = new Date().getTime() + EXPIRETIME;
        LocalStorage.set("expiretime", expireTime);
    }
    let curTime = new Date().getTime();
    // console.log(expireTime - curTime);
    if (!token || expireTime != undefined && expireTime * 1 < curTime) {
        LocalStorage.clearAll();
        return router.push("/login");
    } else {
        //判断是否有访问该路径的权限
        const urls = store.state.userInfo.url;
        //如果是管理员
        if (store.state.userInfo.isAdmin) {
            return next();
        } else {
            if (urls.indexOf(to.path) > -1|| whiteList.indexOf(to.path)>-1) {
                //则包含该元素
                window.sessionStorage.setItem("activePath", to.path);
                return next();
            } else {
                return next();
            }
        }
    }
})


export default router
